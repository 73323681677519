// Import dependencies
import React from "react";
import { navigate } from "gatsby";
import {Button, Col, Form, Row} from "antd";

// Import components
import GeneralButton from "../form/buttons/GeneralButton";

// Import styles
import "../../pages/step.css";
import "../../pages/recycle.css";
import Checkout from "../billing/checkout";
import { ParagraphText } from "../../styled-components/UILibrary";
import { Separator } from "../separators/separator";

/*
    Form for the Step 4 of the checkout process page
*/
class Step5Form extends React.Component {
  handleSubmit = e => {
    const { blockedDone } = this.props;

    e.preventDefault();

    if (blockedDone) return;

    this.props.form.validateFields(err => {
      if (!err) {
        navigate("/confirmation", {
          state: {
            hasSteps: true,
            title: "Booking confirmed",
          },
        });
        this.props.recordOrderDetails();
      }
    });
  };

  render() {
    const { blockedDone } = this.props;

    return (
      <Form
        onSubmit={this.handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {this.props.wantsToJoin ? (
          <div className="step__billing">
            {this.props.isLoggedIn ? (
              <>
                <Checkout />
                <ParagraphText
                  separator
                  colour="rgba(200, 210, 216, 1.00)"
                  center
                  margin="margin-top: 15px"
                >
                  Powered by Stripe
                </ParagraphText>
              </>
            ) : (
              <GeneralButton
                title="REGISTER ACCOUNT"
                type="primary"
                fit
                link="/account"

              />
            )}
          </div>
        ) : null}
      <Row className="step__double-row" style={{ marginTop: 30 }} type="flex">
        <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
        >
          <div className="step__title-input-container">
            <div className="step__button-row" style={{ marginBottom: 80 }}>
                <div  onClick={() => navigate("/step-4")}>
                  <Button  className={"step__button_back"}>
                    Back
                  </Button>
                </div>

            <div className="recycle-now__button-separator" />
            <div style={{ width: "100%" }}>
              {!false && (
                <GeneralButton
                    disabled={blockedDone}
                    title="Done"
                    type="primary"
                    fit
                    htmlType="submit"
                    margin={{ display: "block", margin: "auto" }}
                />
              )}
            </div>
          </div>
        </div>
        </Col> </Row>
      </Form>
    );
  }
}

export default Form.create({ name: "checkout-4" })(Step5Form);
