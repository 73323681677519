// Import dependencies
import React from "react";
import {Button, Col, Row} from "antd";
import ReactResizeDetector from "react-resize-detector";
// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import {PageContainer, ParagraphText, TitleText,} from "../styled-components/UILibrary";
import Accordion from "../components/accordion/earnMoreAccordion";
import CartItem from "../components/cart/cartItem";
import Step5Form from "../components/checkout/step5Form";
import GeneralCartItem from "../components/cart/genCartItem";
// Import styles
import "./step.css";
import "./recycle.css";
// Import redux
import {connect} from "react-redux";
import {pushCartInformation} from "../redux/actions/checkoutAction";
import {clearCart} from "../redux/actions/cartAction";
import {clearGenCart} from "../redux/actions/generalCartAction";

import {getFirebase} from "../api/firebase";
import PickUpItem from "../components/cart/pickUpItem";
import {navigate} from "gatsby-link";
import HeaderSeparator from "../components/separators/headerSeparator";
import {Separator} from "../components/separators/separator";
import {getCartQuote} from "../util/cart";
import SectionSeparator from "../components/separators/sectionSeparator";
import Arrow from "../assets/icons/left-arrow-blue.svg";

/* Step 4 of the checkout process page */
class Step5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wantsToJoin: false,
      currentWidth: 0,
      isMember: false,
    };
    this.changeDesire = this.changeDesire.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  changeDesire = desire => {
    this.setState({ wantsToJoin: desire });
  };

  onResize = width => {
    this.setState({ currentWidth: width });
  };

  // Sends data from this form to redux
  recordOrderDetails = () => {
    if (this.props.cart.length > 0 || this.props.generalCart.length > 0) {
      this.props.dispatch(
        pushCartInformation({
          items: this.props.cart.length > 0 ? this.props.cart : null,
          generalItems:
            this.props.generalCart.length > 0 ? this.props.generalCart : null,
          userToken: {
            uid: this.props.userToken
              ? this.props.userToken.uid
              : "User not registered",
          },
          collection_fee: this.props.checkout.order_info.collection_fee,
        })
      );

      // Clear carts
      this.props.dispatch(clearCart());
      this.props.dispatch(clearGenCart());
    }
  };

  componentDidMount() {
    if (this.props.userToken) {
      const app = import("firebase/app");
      const firestore = import("firebase/firestore");

      Promise.all([app, firestore]).then(([firebase]) => {
        getFirebase(firebase)
          .firestore()
          .collection("users")
          .doc(this.props.userToken.uid)
          .onSnapshot(doc => {
            // Get data and store in state
            let data = doc.data();
            if (!data) return;
            this.setState({
              isMember: data.membership_info.is_member,
            });
          });
      });
    }
  }

  render() {
    const {
      cart,
      generalCart,
      userToken,
      checkout: {
        order_info: {
          collection_method = "",
          collection_vehicle = "",
          collection_fee = 0,
          collection_details: {
            collection_date = "",
            collection_time = "",
            address = "",
            postal_code = "",
          } = {},
        } = {},
      } = {},
    } = this.props;
    const { isMember } = this.state;
    const wePickUpMethod = collection_method === "We pick up";
    const collectionFee = collection_fee;
    const totalPrice = getCartQuote(cart, generalCart) - collectionFee;
    // const blockedDone = !isMember && totalPrice < parseInt(this.props.configs["minimum_checkout"]);
    const totalCount = cart.length + generalCart.length;
    const blockedDone = totalCount < 1;
//    const blockedDone = totalPrice < 0.0;

    return (
      <ReactResizeDetector handleWidth onResize={this.onResize}>
        <Layout noFooter>
          <SEO title="Checkout" />
          <SectionSeparator />
          <PageContainer>

            <Separator height={40} />

            <Row
              gutter={this.state.currentWidth > 767 ? 30 : 0}
              className="step__double-row"
              type="flex"
            >
              <Col
                xs={24}
                sm={24}
                md={{ span: 16, offset: 4 }}
                lg={{ span: 16, offset: 4 }}
                xl={{ span: 16, offset: 4 }}
              >
                <div className="step__single-row">
                  <div className="step_tittle-text" style={{marginBottom: "15px"}}>
                    Order review
                  </div>
                </div>
                <div className="step__single-row">
                  <div className="step-text">
                    Please review and confirm your order
                  </div>
                </div>
              </Col>
              {cart &&
                cart.map((item, i) => (
                  <Col
                    xs={24}
                    sm={24}
                    md={{ span: 16, offset: 4 }}
                    lg={{ span: 16, offset: 4 }}
                    xl={{ span: 16, offset: 4 }}
                    style={{ marginTop: 15 }}
                    key={i}
                  >
                    {item.promo_code_value ? (
                      <GeneralCartItem
                        forReview
                        item={"Promo code"}
                        quote={"$" + item.promo_code_value}
                      />
                    ) : (
                      <CartItem
                        forReview
                        // separateModel={
                        //   window.matchMedia("(max-width: 768px)").matches
                        // }
                        id={item.item_id}
                        brand={item.brand}
                        model={item.model}
                        condition={item.condition}
                        powersOn={item.powersOn}
                        quote={`$${item.quote}`}
                      />
                    )}
                  </Col>
                ))}
              {generalCart
                ? generalCart.map((item, i) => (
                    <Col
                      xs={24}
                      sm={24}
                      md={{ span: 16, offset: 4 }}
                      lg={{ span: 16, offset: 4 }}
                      xl={{ span: 16, offset: 4 }}
                      style={{ marginTop: 15 }}
                      key={i}
                    >
                      {item.promo_code_value ? (
                        <GeneralCartItem
                          forReview
                          item={"Promo code"}
                          quote={"$" + item.promo_code_value}
                        />
                      ) : (
                        <GeneralCartItem
                          forReview
                          id={item.item_id}
                          item={item.item}
                          quantity={`Quantity: ${item.quantity}`}
                          quote={`Item price: $${item.quote}`}
                          total_price={`$${item.total_price}`}
                        />
                      )}
                    </Col>
                  ))
                : null}

              <Col
                xs={24}
                sm={24}
                md={{ span: 16, offset: 4 }}
                lg={{ span: 16, offset: 4 }}
                xl={{ span: 16, offset: 4 }}
                style={{ marginTop: 15 }}
              >
                <div className="review__another">
                  <div  onClick={() => navigate("/")}>
                    <Button  className={"step__button_more"}>
                      + ADD MORE
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            {wePickUpMethod && (
              <Row
                gutter={this.state.currentWidth > 767 ? 30 : 0}
                className="step__double-row"
                type="flex"
              >
                <Col
                  xs={24}
                  sm={24}
                  md={{ span: 16, offset: 4 }}
                  lg={{ span: 16, offset: 4 }}
                  xl={{ span: 16, offset: 4 }}
                >
                  <div
                    style={{ margin: "30px 0", border: ".5px solid #8697A3" }}
                  />

                  <div
                    margin="margin-bottom: 20px"
                    otherFont
                    colour="black"
                    fontSize={22}
                    className="step5-collection-details-title"
                  >
                    Collection details
                  </div>

                  <PickUpItem
                    collectionMethod={collection_method}
                    address={address}
                    date={collection_date}
                    time={collection_time}
                    postalCode={postal_code}
                    collectionVehicle={collection_vehicle}
                    collectionFee={collection_fee}
                  />
                </Col>
              </Row>
            )}

            {!isMember && (
              <>
                <Separator height={49} />
                  <div onClick={() => navigate("/pricing")}>
                    <div className="step__member-item" style={{
                      width: "230px", padding: "0px 17px", height: "50px",
                      borderRadius: "10px", cursor: "pointer"
                    }}>
                      <div className={"step__button_join"}>
                        Join for free collection
                      </div>
                      <div style={{marginLeft: "10px", marginTop: "4px"}}>
                        <Arrow style={{width: 15.58, height: 15.18, color: "#4D90F7"}}/>
                      </div>

                    </div>
                  </div>
              </>
            )}

            <Separator height={30} />

            <div className="step5_pay_title">
              We'll pay you:&nbsp;<div className="step5_pay_price">{`$${totalPrice}`}</div>
            </div>


            {blockedDone && (
              <ParagraphText
                colour="#D62919"
                fontSize={24}
              >{`Checkout minimum is $${parseInt(this.props.configs["minimum_checkout"])}!`}</ParagraphText>
            )}

            <Separator height={15} />

            <Accordion
              className="accordion"
              title="Got a promo code?"
              styles={
                this.state.currentWidth > 576
                  ? { marginTop: 20, maxWidth: 400, width: "100%" }
                  : { marginTop: 20, width: "100%" }
              }
              onChange={this.changeDesire}
              isPremiumOrder={!!cart.length}
            />
            <Separator height={55} />
            <Step5Form
              blockedDone={blockedDone}
              wantsToJoin={this.state.wantsToJoin}
              recordOrderDetails={this.recordOrderDetails}
              isLoggedIn={userToken}
            />
          </PageContainer>
        </Layout>
      </ReactResizeDetector>
    );
  }
}

// Connect redux to component
const mapStateToProps = state => ({
  cart: state.cart,
  generalCart: state.generalCart,
  userToken: state.user.userToken,
  checkout: state.checkout,
  configs: state.product.configs
});

export default connect(mapStateToProps)(Step5);
