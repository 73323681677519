import React from "react"
import { ParagraphText } from "../../styled-components/UILibrary"

// Import redux
import { connect } from "react-redux"
import { STRIPE_CONSTANT, DEPLOY_TYPE } from "../../constants/stripe"

const Checkout = class extends React.Component {
  componentDidMount() {
    this.stripe = window.Stripe(STRIPE_CONSTANT[DEPLOY_TYPE]["STRIPE_KEY"])
  }

  async redirectToCheckout(event) {
    event.preventDefault()
    const { error } = await this.stripe.redirectToCheckout({
      items: [{ plan: STRIPE_CONSTANT[DEPLOY_TYPE]["LIFESTYLE_PLAN"], quantity: 1 }],
      successUrl: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"] + "step-3",
      cancelUrl: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"] + "step-3",
      clientReferenceId: this.props.userToken.uid,
    })
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "3px 3px 0 0 #DCE6ED",
          borderRadius: 13,
          padding: "13px 55px",
          backgroundColor: "rgba(84, 188, 131, 1.00)",
          cursor: "pointer",
        }}
        onClick={event => this.redirectToCheckout(event)}
      >
        <ParagraphText colour="#ffffff" separator>
          CHECKOUT SECURELY
        </ParagraphText>
      </div>
    )
  }
}

// Connect redux to component
const mapStateToProps = state => ({
  userToken: state.user.userToken,
})
export default connect(mapStateToProps)(Checkout)
