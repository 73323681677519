// Import dependencies
import React from "react";

// Import components
import {
  ParagraphText,
  NewBoxComponent,
} from "../../styled-components/UILibrary";

// Import styles
import "./pick-up-item.css";
import {Separator} from "../separators/separator";

/* Pick up Item component for  Review Order */
const PickUpItem = ({
  address = "",
  date = "",
  time = "",
  collectionMethod = "",
  postalCode = "",
  collectionVehicle = "",
  collectionFee = "",
  credit_preference = "",
  creditPreference = false,
  addonsFee = "",
}) => (
    <div className="pick-up-item__container">
      { creditPreference ? <p>Method <span>{credit_preference}</span></p>
        : (<>
            <p>Method <span>{collectionMethod}</span></p>
            <p>Date <span>{date}</span></p>
            <p>Time <span>{time.toUpperCase()}</span></p>
            <p>Address <span>{address.toUpperCase()}</span></p>
            <p>Postal code <span>{postalCode.toUpperCase()}</span></p>
            <p>Vehicle <span>{collectionVehicle}</span></p>
            <p>Collection fee <span>${collectionFee}</span></p>
            <p>Add-ons <span>${addonsFee}</span></p>
          </>)
      }
    </div>
);

export default PickUpItem;
